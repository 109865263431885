<template>
  <div class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <form class="row purchase-ht4">
      <div class="col-12 pb-1">
        <div class="row">
          <div class="col-10 pl-0">
            <h4 class="content-title content-title-xs">New Estimate</h4>
          </div>
          <div class="col-2 float-right text-right pr-0 pl-5">
            <select
              v-model="formData.branch"
              class="form-control right-select-drop"
              :disabled="branchDisabled"
              @change="getDataByBranch(formData.branch)"
            >
              <option value>Select Branch</option>
              <option
                v-for="(branch, index) in dataLists6.branches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-3 bg-theam-secondary mx-0 p-2">
        <div class="purchase-supplier-ht">
          <div>
            <span class="title-supplier">Estimate</span>

            <select
              v-model="user_type"
              class="form-control right-select-drop user-type"
              id="user_type"
              @change="usersType()"
            >
              <option value>Select User Type</option>
              <option value="customer">Customer</option>
              <option value="lead">Lead</option>
            </select>
          </div>
          <div class="input-group py-2">
            <button class="btn-primary input-group-text" id="lead_name">
              <i class="fa fa-user"></i>
            </button>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              v-model="formData.user_name"
              data-toggle="modal"
              data-target="#leadList"
              @click="openLeadListComponent()"
              readonly
            />
          </div>
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label for="date" class="page-text">Estimate Date *</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                type="date"
                class="form-control"
                id="estimate_date"
                name="estimate_date"
                v-model="formData.estimate_date"
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-5 mr-0 pr-0">
              <label for="purchase_id" class="page-text">Estimate ID</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                type="text"
                class="form-control"
                id="estimate_id"
                name="estimate_id"
                placeholder="EST005"
                disabled
                v-model="formData.estimate_code"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-5 mr-0 pr-0">
              <label for="date" class="page-text">Validity</label>
            </div>
            <div class="col-7 ml-0 pl-0">
              <input
                type="date"
                class="form-control"
                id="validity_date"
                name="validity_date"
                v-model="formData.validity"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 mx-0 pl-2 pr-0 purchase-ht2">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-4 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    type="button"
                    class="btn-primary input-group-text"
                    id="product"
                  >
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="items.name"
                  placeholder="Items"
                  data-toggle="modal"
                  data-target="#estimateItemList"
                  @click="openEstimateItemComponent()"
                  readonly
                />
              </div>
            </div>

            <div
              class="col-2 mr-0 pr-0"
              v-if="itemStockable && !authData.general_setting.org_type == 0"
            >
              <div class="page-text">Stock *</div>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  name="quantity"
                  v-model="items.available"
                  disabled
                />
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    id="quantity"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div
              class="col-3 mr-0 pr-0"
              v-if="dataSets.item_setting.barcode == 1"
            >
              <div class="page-text">Barcode</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="barcode">
                    <i class="fas fa-barcode"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Barcode"
                  @blur="getDataByBarcode"
                  v-model="items.barcode"
                />
              </div>
            </div>
            <div class="col-2 pr-0" v-if="dataSets.item_setting.expiry == 1">
              <div v-if="itemStockable">
                <div class="page-text">Expiry Date</div>
                <div class="input-group">
                  <input
                    type="date"
                    class="form-control"
                    name="expiry_date"
                    :class="{ expiry_date: itemExpired }"
                    v-model="items.expiry_date"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pt-2 pr-0">
              <div class="page-text">Description</div>
              <textarea
                name="item_description"
                class="form-control ht-49"
                v-model="items.description"
              ></textarea>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-3 mr-0 pr-0 pt-2 zi-0">
                  <div class="page-text">Quantity *</div>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0"
                      name="quantity"
                      min="1"
                      v-model="items.quantity"
                      @keyup="calculateDiscount()"
                      @change="calculateDiscount()"
                    />
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        id="quantity"
                        style="background: #8db8ee; color: black"
                        >{{ items.unit_type }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-3 mr-0 pt-2">
                  <div class="page-text">Sales Rate *</div>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      name="rate"
                      min="0"
                      step="any"
                      placeholder="Amount"
                      v-model="items.sales_rate"
                      @keyup="calculateDiscount()"
                      @change="calculateDiscount()"
                    />
                  </div>
                </div>
                <div class="col-3 pl-0 pt-2">
                  <div class="page-text">Sales Discount</div>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0.00"
                      step="any"
                      name="sales_discount"
                      min="0"
                      v-model="items.sales_discount_percentage"
                      @keyup="calculateDiscount()"
                      @change="calculateDiscount()"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text px-1" style="min-width: 0px"
                        >%</span
                      >
                    </div>
                    <input
                      type="number"
                      class="form-control wd-20pimp"
                      placeholder="0.00"
                      step="any"
                      min="0"
                      name="sales_discount"
                      v-model="items.sales_discount_amount"
                      @keyup="changeSalesRate()"
                      @change="changeSalesRate()"
                      @keydown.tab.exact="
                        options.edit == true ? updateItem() : addItem()
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-1 mr-0 pr-0">
                  <label for="date" class="page-text">Total</label>
                </div>
                <div class="col-3 ml-0 pl-0">
                  <input
                    type="number"
                    class="form-control"
                    id="total"
                    name="total"
                    step="any"
                    disabled
                    v-model="items.total"
                  />
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <div v-if="options.edit">
                    <div
                      class="btn btn-success btn-xs"
                      @click="updateItem"
                      type="button"
                    >
                      Update Item
                    </div>
                    <div
                      class="btn btn-danger btn-xs ml-2"
                      @click="closeUpdate()"
                      type="button"
                    >
                      Close
                    </div>
                  </div>
                  <div
                    v-else
                    class="btn btn-success btn-xs"
                    @click="addItem"
                    type="button"
                  >
                    Add Item
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th scope class="wd-5p table-start-item">S.N.</th>
                <th scope class="wd-50p">Particular</th>
                <th scope class="wd-15p">Qty</th>
                <th scope class="wd-10p">Rate</th>
                <th scope class="wd-10p">Discount</th>
                <th scope class="wd-10p">Total</th>
                <th scope class="wd-10p text-right table-end-item">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} {{ item.unit_type }}</td>
                <td>{{ item.sales_rate }}</td>
                <td>{{ parseDigit(item.sales_discount_amount) }}</td>
                <td>{{ parseDigit(item.total) }}</td>
                <td class="text-right table-end-item">
                  <a href="javascript:;" class="mr-2" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="mr-0"
                    @click="removeItem(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 bg-theam-secondary p-2 mt-2">
        <div class="row">
          <div class="col-3 pr-0">
            <div class="page-text">Note</div>
            <textarea
              name="note"
              class="form-control page-note"
              v-model.trim="formData.note"
            ></textarea>
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-3 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model="formData.total"
                    placeholder="0.00"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">
                  VAT-able Amount
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="
                      Please adjust vatable amount only after adding the extra fields.If you add/edit extra field after editing the vatable amount,vatable amount will be reset and will be recalculated.
                    "
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model="formData.vatable_amount"
                    placeholder="0.00"
                    @keyup="calculateCustomAmount()"
                    @change="calculateCustomAmount()"
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">VAT Amount</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="0.00"
                    v-model="formData.vat_amount"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-3 mr-0 pr-0"
                v-if="authData.general_setting.vatable == 1"
              >
                <div class="page-text">Estimate Total</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.estimate_total"
                    placeholder="0.00"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                v-for="(extra_field, index) in dataLists6.sales_extra_fields"
                :key="index"
                class="col-2 pt-2 mr-0 pr-0"
              >
                <div class="page-text">
                  {{ extra_field.title }} ({{
                    extra_field.type == 0 ? "+" : "-"
                  }})
                  <i
                    class="fa fa-info-circle primary"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    :title="extra_field.vat == 1 ? 'Vatable' : 'Non Vatable'"
                  ></i>
                </div>
                <div class="input-group">
                  <input
                    :id="`ext-${extra_field.slug}`"
                    :placeholder="extra_field.title"
                    class="form-control"
                    step="any"
                    min="0"
                    type="number"
                    v-model="formData.reactiveExtraFields[index]"
                    @keyup="extraFields({ ...extra_field })"
                    @change="extraFields({ ...extra_field })"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row justify-content-end mr-0">
              <button
                class="col-7 btn btn-success btn-sm btn-min-wd"
                type="submit"
                @click.prevent="submitData()"
                :disabled="submitting || loading"
              >
                <span v-if="loading">Loading...</span>
                <span v-else>{{
                  submitting ? "Submitting...." : "Submit"
                }}</span>
              </button>
              <button
                class="col-7 btn btn-warning btn-sm btn-min-wd my-2"
                type="button"
                @click.prevent="reset"
              >
                Reset
              </button>
              <button
                type="button"
                class="col-7 btn btn-danger btn-sm btn-min-wd"
                tag="button"
                v-if="checkSinglePermission('view_estimate')"
                @click.prevent="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <NewItem />
    <ListLead ref="listLeadComponent" @userClicked="leadClicked" />
    <EstimateItemComponent
      @clicked="componentItemClicked"
      ref="estimateItemComponent"
    />
    <EstimateSlip @printSlip="printSlip" />
  </div>
</template>
<script>
import NewItem from "../Item/NewItem";
import ListLead from "./Components/LeadListComponent";
import EstimateItemComponent from "./Components/EstimateItemComponent";
import EstimateSlip from "./Components/EstimateSlip";

import Services from "./Services/Service";
import { mapGetters } from "vuex";
export default {
  components: {
    NewItem,
    ListLead,
    EstimateSlip,
    EstimateItemComponent,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      dataSets: {
        items: [],
        branches: [],
        sales_setting: [],
        item_setting: [],
      },
      user_type: "",
      branchDisabled: false,
      itemExpired: false,
      itemStockable: true,
      submitting: false,
      loading: true,
      newVatable: 0,
      formData: {
        extra_fields: [],
        reactiveExtraFields: [],
        user_id: "",
        user_type: "",
        user_name: "",
        estimate_code: "",
        branch: "",
        lead_id: "",
        estimate_date: null,
        validity: "",
        vatable_amount: null,
        vat_amount: null,
        estimate_total: null,
        total: null,
        note: null,
        items: [],
      },
      items: {
        item_id: null,
        vat_applicable: null,
        item_name: null,
        available: null,
        quantity: 0,
        unit_type: null,
        sales_rate: 0,
        sales_discount_percentage: 0,
        sales_discount_amount: 0,
        total: 0,
        description: "",
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists6",
      "dataLists8",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
      "today",
      "authData",
    ]),
    ...mapGetters("estimate", ["leads", "userType", "itemsByBranch"]),
    ...mapGetters("leads", ["lead"]),
  },
  mounted() {
    this.getData();
    this.formData.estimate_date = this.today;
    //this.formData.estimate_code = new Date().getTime();
  },
  methods: {
    //Opening the lead listing component
    openLeadListComponent() {
      this.$store.dispatch("modalWithMode", {
        id: "leadList",
        mode: "create",
      });
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.listLeadComponent.$refs.leadSearch.focus();
        });
      }, 600);
    },
    //when lead is selected from a component
    leadClicked(details) {
      this.formData.user_type = this.user_type;
      this.formData.user_id = details.id;
      this.formData.user_name = details.name;
      this.$store.dispatch("modalWithMode", "");
    },
    usersType() {
      this.formData.user_name = "";
      this.formData.user_id = "";
      this.getUserData();
    },
    getUserData() {
      this.$store.commit("estimate/setUserType", this.user_type);
      this.$store.commit("estimate/setLeads", []);
      this.$store.commit("estimate/setLeadSearchLoading", true);
      Services.getUserByUserType(this.user_type)
        .then((response) => {
          this.$store.commit("estimate/setLeads", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("estimate/setLeadSearchLoading", false);
        });
    },
    getData() {
      this.loading = true;
      Services.getDataForEstimateCreation()
        .then((response) => {
          let value = response.data.data;
          this.formData.estimate_code = value.estimate_code;
          this.$store.commit("setDataLists6", value);
          this.dataSets.branches = value.branches;
          this.dataSets.sales_setting = value.sales_setting;
          this.dataSets.item_setting = value.item_setting;
          this.$store.commit("estimate/setItemGroups", value.item_groups);
          if (this.dataSets.branches.length == 1) {
            this.branchDisabled = true;
            this.formData.branch = value.branches[0]["id"];
            this.getDataByBranch(this.formData.branch);
          }
          //if the user came from Lead section by pressin the new estimate button
          if (Object.keys(this.lead).length != 0) {
            this.user_type = "lead";
            this.formData.user_type = "lead";
            this.formData.user_id = this.lead.id;
            this.formData.user_name = this.lead.name;
            this.getUserData();
          }
        })
        .catch((error) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataByBranch(branchId) {
      this.items = {};
      this.itemsByBranch.items = [];
      this.$store.commit("estimate/setItemSearchLoading", true);
      this.itemExpired = false;
      Services.getPurchaseItemsByBranch(branchId)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("estimate/setItemsByBranch", value);
          this.$store.commit("estimate/setBranchId", branchId);
          this.dataSets.items = value.items;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("estimate/setItemSearchLoading", false);
        });
    },

    openEstimateItemComponent() {
      if (this.formData.branch) {
        this.$store.dispatch("modalWithMode", {
          id: "estimateItemList",
          mode: "create",
        });
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.estimateItemComponent.$refs.itemSearch.focus();
          });
        }, 600);
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    // receiving data from child component i.e estimateItemComponent
    componentItemClicked(details) {
      this.items = [];
      if (details["branch"] == null && details["purchase_id"] == null) {
        details["available"] = 1;
        details["quantity"] = 1;
        this.itemStockable = false;
      } else {
        this.itemStockable = true;
      }
      this.items = { ...details };
      this.items.purchase_id = details.purchase_id;
      this.items.item_id = details.item.id;
      this.items.name = details.item.name;
      this.items.unit_type = details.item.unit_type.title;
      this.items.description = details.item.description;
      this.items.vat_applicable = details.item.vat_applicable;
      this.items.quantity = 1;
      this.items.total = details.sales_rate - details.sales_discount_amount;
      this.formData.date = this.today;
      if (details.expiry_date) {
        let curretnDateInMiliSecond = new Date().getTime();
        let itemExptime = new Date(this.items.expiry_date);
        let itemExpTimeinMiliSecond = new Date(itemExptime).getTime();

        if (curretnDateInMiliSecond > itemExpTimeinMiliSecond) {
          this.itemExpired = true;
        } else {
          this.itemExpired = false;
        }
      } else {
        this.itemExpired = false;
      }

      // this.calculateDiscount();
    },
    getDataByBarcode() {
      let branchId = this.formData.branch;
      if (branchId) {
        Services.getDataByBarcode(this.items.barcode, branchId)
          .then((response) => {
            let value = response.data.data;
            if (value) {
              this.items = { ...value };
              this.items.item_id = value.item.id;
              this.items.unit_type = value.item.unit_type.title;
              this.items.name = value.item.name;
              this.items.quantity = 1;
            } else {
              this.items = {};
              this.setNotification("No product Found");
            }
          })
          .catch((error) => {
            this.items = {};
            if (error.response.status == 500) {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          });
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    changeSalesRate() {
      let saleRate = this.items.sales_rate;
      let saleDiscountAmt = this.items.sales_discount_amount;
      let saleDiscountPer =
        (saleDiscountAmt / (saleRate * this.items.quantity)) * 100;
      if (saleDiscountAmt == 0) {
        this.items.sales_discount_percentage = 0;
        this.calculateItemTotal();
      } else {
        this.items.sales_discount_percentage = saleDiscountPer;
        this.calculateItemTotal();
      }
    },
    calculateDiscount() {
      let rate = this.items.sales_rate;
      let itemQuantity = this.items.quantity;
      let s_discountPerc = this.items.sales_discount_percentage;
      if (rate >= 0 && s_discountPerc >= 0) {
        let discAmount = this.parseDigit(
          (s_discountPerc * (itemQuantity * rate)) / 100
        );
        this.items.sales_discount_amount = this.parseDigit(discAmount);
      } else if (rate >= 0 && s_discountPerc == 0) {
        this.items.sales_discount_amount = 0;
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (s_discountPerc * rate) / 100
      );
      this.calculateItemTotal();
    },
    calculateTotal() {
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.estimate_total = 0;
      this.formData.total = 0;
      var itemVatable = false;
      this.formData.items.forEach((item) => {
        this.formData.total = this.parseDigit(
          this.formData.total + parseFloat(item.total)
        );
        if (item.vat_applicable == 1) {
          itemVatable = true;
          this.formData.vatable_amount = this.parseDigit(
            parseFloat(this.formData.vatable_amount || 0) +
              parseFloat(item.total)
          );
        }
      });
      if (itemVatable) {
        this.formData.vatable_amount = this.parseDigit(
          parseFloat(this.formData.vatable_amount || 0) +
            parseFloat(this.newVatable || 0)
        );
      }

      this.calculateCustomAmount();
    },

    calculateCustomAmount() {
      this.formData.vat_amount = this.parseDigit(
        parseFloat(this.formData.vatable_amount || 0) * 0.13
      );
      this.formData.estimate_total = this.parseDigit(
        parseFloat(this.formData.total) +
          parseFloat(this.formData.vat_amount || 0)
      );
      if (this.formData.extra_fields) {
        this.formData.extra_fields.forEach((extra) => {
          if (parseInt(extra.type) == 0) {
            this.formData.estimate_total = this.parseDigit(
              parseFloat(this.formData.estimate_total) +
                parseFloat(extra.amount || 0)
            );
            this.formData.sales_amount = this.parseDigit(
              parseFloat(this.formData.sales_amount || 0) +
                parseFloat(extra.amount || 0)
            );
          } else {
            this.formData.estimate_total = this.parseDigit(
              parseFloat(this.formData.estimate_total) -
                parseFloat(extra.amount || 0)
            );
            this.formData.sales_amount = this.parseDigit(
              parseFloat(this.formData.sales_amount || 0) -
                parseFloat(extra.amount || 0)
            );
          }
        });
      }
    },
    calculateItemTotal() {
      if (parseFloat(this.items.sales_discount_amount) > 0) {
        this.items.total = this.parseDigit(
          this.items.quantity * parseFloat(this.items.sales_rate || 0) -
            parseFloat(this.items.sales_discount_amount || 0)
        );
      } else {
        this.items.total = this.parseDigit(
          this.items.quantity * parseFloat(this.items.sales_rate || 0)
        );
      }
      this.items.total = this.parseDigit(this.items.total);
    },
    addItem() {
      if (this.itemValidation()) {
        if (this.formData.items.length > 0) {
          let item_id = this.items.item_unique_id;
          let filteredItem = this.formData.items.find(function (data) {
            return data.item_unique_id == item_id;
          });
          if (filteredItem == undefined) {
            this.formData.items.push({ ...this.items });
            // to disable the branch selection
            this.branchDisabled = true;
            // to change the color of date if expired
            this.itemExpired = false;
            // to clear the item fields
            this.clearItem();
            this.itemStockable = true;
            // this.addFormDataToLocalStorage(this.formData);
          } else {
            let err = `${this.items.name} already in item list.`;
            this.setNotification(err);
            this.clearItem();
          }
        } else {
          this.formData.items.push({ ...this.items });
          // this.addFormDataToLocalStorage(this.formData);
          this.branchDisabled = true;
          this.itemExpired = false;
          this.itemStockable = true;
          this.clearItem();
        }
        this.calculateTotal();
      }
    },

    itemValidation() {
      if (!this.items.item_id) {
        this.setNotification("Please select item first.");
        return false;
      }
      if (this.items.quantity <= 0) {
        this.setNotification("Quantity must be greater than 0.");
        return false;
      }
      if (this.items.sales_rate <= 0) {
        this.setNotification("Sales Rate must be greater than 0.");
        return false;
      }
      if (this.items.sales_discount_amount < 0) {
        this.setNotification("Discount amount cannot be less than 0.");
        return false;
      }
      return true;
    },
    removeItem(index) {
      this.$swal({
        title: "Do you really want to remove this item ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.items.splice(index - 1, 1);
          if (this.formData.items.length == 0) {
            this.branchDisabled = false;
          }

          this.calculateTotal();
          // this.addFormDataToLocalStorage(this.formData);
        }
      });
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      if (this.items["validity"] == "Expired") {
        this.itemExpired = true;
      } else {
        this.itemExpired = false;
      }
      if (this.items["branch"] == null && this.items["purchase_id"] == null) {
        this.itemStockable = false;
      } else {
        this.itemStockable = true;
      }
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
      if (this.itemValidation()) {
        let dataAdded = false;
        let currentAddedItems = this.formData.items;
        currentAddedItems.forEach((el, index) => {
          if (el.item_unique_id == this.items.item_unique_id) {
            dataAdded = true;
            this.formData.items[index] = { ...this.items };
          }
        });
        if (dataAdded == false) {
          this.formData.items.push({ ...this.items });
        }
        this.items = [];
        this.options.edit = false;
        this.itemStockable = true;
        this.itemExpired = false;
        this.branchDisabled = true;
        this.calculateTotal();
      }
    },
    closeUpdate() {
      this.options.edit = false;
      this.items = [];
      this.itemStockable = true;
      this.itemExpired = false;
    },
    submitData() {
      if (this.formValidation()) {
        this.storeItem();
      }
    },
    formValidation() {
      if (this.formData.items.length == 0) {
        this.setNotification("Please add items first.");
        return 0;
      }
      if (!this.formData.user_id) {
        this.setNotification("Please select the user type.");
        return 0;
      }
      return true;
    },
    storeItem() {
      this.submitting = true;
      Services.storeEstimateData(this.formData)
        .then((res) => {
          if (
            res.status == 200 &&
            res.data.success_message == "Estimate added successfully."
          ) {
            this.reset("estimate");
            // making array because salesSlip always accepts an array of data
            let estimateArray = [];
            estimateArray.push(res.data.data.estimate);
            this.$store.commit("estimate/setDataSets", estimateArray);
            // providing the organization and sales setting to sales slip component
            this.$store.commit("estimate/setSlipSettings", res.data.data);
            //poping up the EstimateSlip modal
            this.$store.commit("setDataId", res.data.data.estimate.id);
            this.$store
              .dispatch("modalWithMode", { id: "estimateSlip", mode: "read" })
              .then(() => {
                // this.printSlip(res.data.data.estimate.id);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          //validation error
          if (error.response.status == 422) {
            let err = Object.values(error.response.data.errors).flat();
            this.setNotification(err);
          } else {
            let err = error.response.data.error_message;
            this.setNotification(err);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    printSlip() {
      window.print();
    },
    clearItem() {
      this.items.vat_applicable = null;
      this.items.item_id = null;
      this.items.name = null;
      this.items.available = 0;
      this.items.quantity = 0;
      this.items.sales_rate = 0;
      this.items.sales_discount_percentage = 0;
      this.items.sales_discount_amount = 0;
      this.items.description = "";
      this.items.total = 0;
      this.items.barcode = null;
      this.items.expiry_date = null;
      this.items.unit_type = null;
    },
    extraFields(details) {
      let index = this.formData.extra_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields[index].amount = amount;
        } else {
          this.formData.extra_fields[index].amount = 0;
        }
      } else {
        let amount = document.getElementById(`ext-${details.slug}`).value;
        if (amount) {
          this.formData.extra_fields.push({
            slug: details.slug,
            type: details.type,
            vat: details.vat,
            amount: amount,
          });
        }
      }
      this.calculateVatableAmtExtraFieldCase();
      // adding data to localStorage
      // this.addFormDataToLocalStorage(this.formData);
    },
    calculateVatableAmtExtraFieldCase() {
      this.formData.extra_fields.forEach((extra, index) => {
        if (index == 0) {
          this.newVatable = 0;
        }
        if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 1) {
          this.newVatable = this.newVatable - parseFloat(extra.amount || 0);
        } else if (parseInt(extra.vat) == 1 && parseInt(extra.type) == 0) {
          this.newVatable = this.newVatable + parseFloat(extra.amount || 0);
        } else {
          this.newVatable = this.newVatable + 0;
        }
      });
      this.calculateTotal();
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    reset(type = null) {
      this.formData.user_id = "";
      this.formData.user_name = "";
      this.formData.user_type = "";
      this.user_type = "";
      this.formData.vatable_amount = null;
      this.formData.vat_amount = null;
      this.formData.extra_fields = [];
      this.formData.total = null;
      this.formData.items = [];
      this.formData.note = "";
      this.items.unit_type = null;
      this.items.unit_typevat_applicable = null;
      this.items.unit_typeitem_id = null;
      this.items.unit_typename = null;
      this.items.unit_typeavailable = null;
      this.items.unit_typequantity = 0;
      this.items.unit_typesales_rate = 0;
      this.items.unit_typesales_discount_percentage = 0;
      this.items.unit_typesales_discount_amount = 0;
      this.items.unit_typetotal = 0;
      this.items.unit_typebarcode = null;
      this.items.unit_typeexpiry_date = null;
      this.dataSets.items = [];
      this.formData.extra_fields = [];
      this.formData.reactiveExtraFields = [];
      this.formData.searchCustomer = "";
      this.formData.estimate_total = null;
      this.formData.validity = null;
      this.formData.estimate_date = this.today;
      this.$store.commit("estimate/setUserType", "");
      this.$store.commit("estimate/setLeads", []);
      // single and multiple branch case
      if (this.dataSets.branches.length == 1) {
        this.branchDisabled = true;
        if (type == "estimate") {
          this.formData.estimate_code = new Date().getTime();
        }
      } else {
        this.formData.branch = "";
        if (type == "estimate") {
          this.branchDisabled = false;
          this.formData.estimate_code = new Date().getTime();
        } else {
          this.branchDisabled = false;
          this.$store.commit("estimate/setItemsByBranch", []);
        }
      }
      this.itemStockable = true;
      this.itemExpired = false;
      this.items = [];
      this.$store.dispatch("modalWithMode", "");
      // this.removeDataFromLocalStorage();
    },
    removeDataFromLocalStorage() {
      localStorage.removeItem("estimateFormData");
      localStorage.removeItem("estimatePaymentMethod");
    },
    cancel() {
      this.$router.push({ name: "list-estimate" });
    },
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("Sales successfully") >= 0) {
        this.$router.push("/sales/manage");
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
    localStorage.removeItem("items");
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}
.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}
.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}
.input-group-text {
  outline: none;
}
input,
select,
textarea {
  background: #f4f7fa;
}
input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}
.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
.user-type {
  width: 50%;
  float: right;
}
</style>
