<template>
  <div class="row">
    <!--      lead lists-->
    <div class="col-md-12">
      <div
        class="modal fade modal_cust"
        id="leadList"
        role="dialog"
        aria-hidden="true"
        v-if="modalId == 'leadList'"
      >
        <div class="modal-dialog listing-popup-place modal_ac_head" role="document">
          <div class="modal-content search-table-container">
            <!-- search field -->
            <div class="form-group has-search mb-0">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="leadSearched"
                @input="searchLead"
                ref="leadSearch"
              />
            </div>
            <table class="table table1">
              <thead>
                <tr>
                  <th scope class="wd-5p table-start-item">S.N.</th>
                  <th scope class="wd-15p">User Id</th>
                  <th scope class="wd-30p">
                    <span>{{ userType ? ucFirst(userType) : '' }} Name</span>
                  </th>
                  <th scope class="wd-30p">Address</th>
                  <th scope class="wd-20p">Phone No</th>
                </tr>
              </thead>
              <tbody v-if="!leadLoading && leads.length > 0">
                <tr v-for="(lead, index) in leads" :key="index" @click="selectLead(lead.id)">
                  <th scope="row" class="table-start-item">{{ ++index }}</th>
                  <td>
                    <span v-if="userType=='customer'">{{lead.customer_id}}</span>
                    <span v-else>{{ lead.lead_id }}</span>
                  </td>
                  <td>{{ lead.name }}</td>
                  <td>{{ lead.address }}</td>
                  <td>
                    <span v-if="userType =='customer'">{{lead.phone}}</span>
                    <span v-else>{{ lead.phone_number}}</span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="leadLoading || leadSearchLoading">
                <tr>
                  <td colspan="11" style="text-align:center;">Loading.....</td>
                </tr>
              </tbody>
              <tbody v-else-if="!leadLoading && leads.length == 0">
                <tr>
                  <td colspan="11" style="text-align:center;">No records found.</td>
                </tr>
              </tbody>

              <tbody v-else-if="errorLead">Error Fetching data from the server.</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Service";
import _ from "lodash";
export default {
  data() {
    return {
      leadLoading: false,
      leadSearched: "",
      errorLead: false,
    };
  },
  computed: {
    ...mapGetters(["modalWithMode", "modalId", "dataLists6"]),
    ...mapGetters("estimate", ["leads", "userType", "leadSearchLoading"]),
  },
  methods: {
    ucFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },
    selectLead(id) {
      let details = this.leads.find(function (data) {
        return data.id == id;
      });
      this.$store.commit("estimate/setSelectedUser", details);
      this.$emit("userClicked", details);
    },
    // Search Lead through ajax request
    searchLead: _.debounce(function () {
      this.leadLoading = true;
      let keyword = this.leadSearched;
      keyword = keyword ? keyword : "all";
      Services.getLeadsBySearchKeyword(keyword, this.userType)
        .then((response) => {
          this.$store.commit("estimate/setLeads", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.leadLoading = false;
        });
    }, 600),
  },
};
</script>
<style scoped>
.active {
  color: #1b52bf !important;
  background: white !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.message-center p {
  text-align: center;
  color: var(--blue);
  font-size: large;
  margin-left: 10px;
}
</style>